import React
from 'react';

import {Button, Modal, Form, Popup, Checkbox, Divider, Dimmer, Loader}
from 'semantic-ui-react';

import { Auth}
from 'aws-amplify';

import {AppCtx, getSchool}
from "./App.js";

import Lambda
from 'aws-sdk/clients/lambda';

export function OrderDlgJ(props)
{
  const [state,setState] = React.useState({isOpen:false,savEn:false,vorname:"",nachname:"",chk1:false,chk2:false,chk3:false,chk4:false,requestRunning:false,requestMsg:""});
  // Global State
  const aCtx = React.useContext(AppCtx);

  // Provisioning trigger, updates ddb
  async function doOrder(){
    setState({...state,requestRunning:true});

    const creds  = Auth.essentialCredentials(await Auth.currentCredentials());
    const lambda = new Lambda({credentials: creds,region:'eu-central-1'})

    const params = {
                     FunctionName  : "AVVSender-master",
                     InvocationType: 'RequestResponse',
                     Payload       : '{"no":"' + aCtx.state.school.no + '","vorname":"' + state.vorname + '","nachname":"' + state.nachname +'","module":"jitsi"}' 
                   };
    // eslint-disable-next-line no-unused-vars
    const request = lambda.invoke(params, async function(err, data) {
     setState({...state,requestRunning:false});
     if (err){
       console.log("doOrder::Invoke callback err:", err, err.stack); // an error occurred
     }
     else{
       console.log("doOrder::Invoke callback done:", data         ); // successful response
       getSchool(aCtx.state.school.no).then((school) => {aCtx.dispatch({type:"ordered",school:school})});
       setState({...state,isOpen:false});
     }
   });
   // Close Dialog while deployment runs
  }
  //
  // ReSet State from current props for resend
  //
  async function onMountResend() {
    Auth.essentialCredentials(await Auth.currentCredentials());
    console.log(aCtx.state);
    setState({ ...state, savEn: false, vorname: aCtx.state.school.vornameJ, nachname: aCtx.state.school.nachnameJ, chk1: true, chk2: true, chk3: true, chk4: true });
  }
  //
  // ReSet State from current props for initial Order
  //
  async function onMount(){
    Auth.essentialCredentials(await Auth.currentCredentials());
    setState({...state,savEn:false,vorname:"",nachname:"",chk1:false,chk2:false,chk3:false,chk4:false});
  }
  //
  // Save inputs and set button states: AccountSelected = good
  //
  function chkState(newState){
    var stateOk = true;

    stateOk &= (newState.vorname.length   > 1);
    stateOk &= (newState.nachname.length  > 1);
    stateOk &= (newState.chk1);
    stateOk &= (newState.chk2);
    stateOk &= (newState.chk3);
    stateOk &= (newState.chk4);

    setState({...newState,savEn:stateOk}); // ;
  }
  // eslint-disable-next-line no-multi-str
  const explainResend = "Die Bestellung Ihres LOGINEO NRW-Messenger Moduls Jitsi ist bereits erfolgt.\
                       Falls Sie die nach der technischen Bereitstellung an <Schulnummer>@schule.nrw.de versandten Dokumente \
                       mit der personalisierten AVV mit den hier eingegebenen Daten für den \
                       LOGINEO-NRW Messenger nicht erhalten haben, können Sie den Versand erneut auslösen. \
                       Dieser erfolgt innerhalb von 5 Minuten an die eMail: <Schulnummer>@schule.nrw.de. \
                       Sollten Sie die eMail nicht ebenfalls zeitnah erhalten, kontrollieren Sie bitte den Spam-Ordner.";


  console.log("OrderDlgJ:aCtx.state.school.aStateJ:",aCtx.state.school.aStateJ)
  if(aCtx.state.school.aStateJ !== 0){
    return (
      <Modal open   = {state.isOpen}
            trigger = {<Popup content ='Jitsi Dokumente nach Bestellung erneut versenden' trigger={<Button primary positive disabled={aCtx.state.school.aState !== 3} content="Jitsi Dokumente erneut versenden" onClick={()=>{setState({...state,isOpen:true});}}/>}/>}
            onMount = { onMountResend }
             dimmer = 'inverted'>
        <Modal.Header>LOGINEO-NRW Messenger Modul Jitsi Dokumente für {aCtx.state.school.name} erneut versenden</Modal.Header>
        <Dimmer.Dimmable as={Modal.Content} dimmed={state.requestRunning}>
          <Dimmer active={state.requestRunning} inverted>
            <Loader disabled={!state.requestRunning} active={state.requestRunning}>{state.requestMsg}</Loader>
          </Dimmer>
          <Modal.Content>
            <Form>
              <Form.Field><label>{explainResend}</label></Form.Field>
              <Form.Field>
                <Form.Input readOnly width={6}
                                     label='Vorname der Schulleitung'
                                      name="vorname"
                                 value={state.vorname}/>
              </Form.Field>
              <Form.Field>
                <Form.Input readOnly width={6}
                                     label='Nachname der Schulleitung'
                                      name="nachname"
                                    value={state.nachname}/>
              </Form.Field>
              <Divider/>
              <Button type="button" positive active 
                onClick={(e) => {e.preventDefault();
                                doOrder();}}>
                Modul Jitsi Dokumente erneut versenden
              </Button>
              <Button type="button" negative
                onClick={() => {setState({...state,isOpen:false});}}>
                  Schliessen
              </Button>
            </Form>
          </Modal.Content>
        </Dimmer.Dimmable>
    </Modal >
    );
  }
  // eslint-disable-next-line no-multi-str
  const explainProc = "Die Bestellung Ihres LOGINEO NRW-Messenger Moduls Jitsi muss durch die Schulleitung erfolgen.\
                       Nach der technischen Bereitstellung erhalten Sie eine Nachricht an <Schulnummer>@schule.nrw.de mit der durch die \
                       hier eingegebenen Daten personalisierten AVV. \
                       Die Bereitstellung erfolgt in der Regel innerhalb von 5 Minuten. \
                       Sollten Sie die eMail nicht ebenfalls zeitnah erhalten, kontrollieren Sie bitte den Spam-Ordner.";

  return(
    <Modal  open  ={state.isOpen}
           trigger={<Popup content ='LOGINEO-NRW Messenger Modul Jitsi bestellen' trigger={<Button primary positive disabled={aCtx.state.school.aState !== 3} content="LOGINEO-NRW Messenger Modul Jitsi bestellen" onClick={()=>{setState({...state,isOpen:true});}}/>}/>}
           onMount={onMount}
           dimmer ='inverted'>
    <Modal.Header>LOGINEO-NRW Messenger Modul Jitsi für {aCtx.state.school.name} bestellen</Modal.Header>
    <Dimmer.Dimmable as={Modal.Content} dimmed={state.requestRunning}>
      <Dimmer active={state.requestRunning} inverted>
        <Loader disabled={!state.requestRunning} active={state.requestRunning}>{state.requestMsg}</Loader>
      </Dimmer>
      <Modal.Content>
        <Form>
          <Form.Field><label>{explainProc}</label></Form.Field>
          <Form.Field>
            <Form.Input required width={6}
                                 label='Vorname der Schulleitung'
                                  name="vorname"
                                 value={state.vorname}
                              onChange={(e) => {chkState({...state,[e.target.name]:e.target.value});}}/>
          </Form.Field>
          <Form.Field>
            <Form.Input required width={6}
                                 label='Nachname der Schulleitung'
                                  name="nachname"
                                 value={state.nachname}
                              onChange={(e) => {chkState({...state,[e.target.name]:e.target.value});}}/>
          </Form.Field>
          <Form.Field required>
             <Checkbox label='Ein positiver Beschluss der Schulkonferenz liegt mir vor'
                        name="chk1"
                     checked={state.chk1}
                    onChange={(e,d) => {
                    chkState({...state, [d.name]:d.checked});}}/>
          </Form.Field>
          <Form.Field required>
             <Checkbox label='Der Schulträger wird von mir kurzzeitig informiert'
                        name="chk2"
                     checked={state.chk2}
                    onChange={(e,d) => {
                    chkState({...state, [d.name]:d.checked});}}/>
          </Form.Field>
          <Form.Field required>
             <Checkbox label='Die Vereinbarung zur Auftragsverarbeitung wird bestätigt'
                        name="chk3"
                     checked={state.chk3}
                    onChange={(e,d) => {
                    chkState({...state, [d.name]:d.checked});}}/>
          </Form.Field>
          <Form.Field required>
             <Checkbox label='Die Bestellung des LOGINEO-NRW Messenger Moduls Jitsi wird von mir bestätigt'
                        name="chk4"
                     checked={state.chk4}
                    onChange={(e,d) => {
                    chkState({...state, [d.name]:d.checked});}}/>
          </Form.Field>
          <Divider/>
          <Button type="button" positive active disabled={!state.savEn}
            onClick={(e) => {e.preventDefault();
                             doOrder();}}>
            LOGINEO-NRW Messenger Modul Jitsi bestellen
          </Button>
          <Button type="button" negative
            onClick={() => {setState({...state,isOpen:false});}}>
            Schliessen
          </Button>
        </Form>
      </Modal.Content>
      </Dimmer.Dimmable>
    </Modal>
  );
}